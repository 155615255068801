import { useState, useEffect } from "react";
import { Carousel, Table, Divider, Flex, Spin, Result, Button, Tooltip } from "antd";
import { LeftCircleOutlined, RightCircleOutlined, CopyTwoTone } from "@ant-design/icons";
import HTMLReactParser from "html-react-parser";
import axios from "axios";
const ProductModal = (props) => {
    const { product_id, store_sync_id, handleClose } = props;
    const [dataSource, setDataSource] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [hovered, setHovered] = useState("");
    const base_url = process.env.REACT_APP_API_URL
    const url = `${base_url}/bulksync/getProductDetails`;

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setHovered("Copied!");
        setTimeout(() => setHovered(false), 1000);
    }
    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "SKU",
            dataIndex: "sku",
            key: "sku",
            render: (text) => (
                <>
                    {text}
                    {
                        text &&
                        <Tooltip placement="topRight" title={hovered}>
                            <CopyTwoTone
                                style={{marginLeft: '8px'}}
                                onMouseOver={() => setHovered("Copy")}
                                onClick={() => {
                                    copyToClipboard(text);
                                }}
                            />
                        </Tooltip>
                    }

                </>
            )
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
        },
    ];

    useEffect(() => {
        // console.log("HERE",product_id,store_sync_id);
            const fetchData = async () => {
                try {
                    const response = await axios.post(
                        url,
                        {
                            product_id: product_id,
                            store_sync_id: store_sync_id,
                        }
                    );
                    if(!response){
                        setError("error getting product details");
                    }
                    setDataSource(response?.data);
                } catch (error) {
                    console.log(error?.response?.status);
                    setError(error?.response?.status);
                } finally {
                    setLoading(false);
                }
            };
            if(product_id && store_sync_id) {
                fetchData();
            }
        }, [product_id, store_sync_id])

    const tableData = dataSource?.variants?.map((variant) => ({
        key: variant?.id,
        title: variant?.title,
        sku: variant?.sku,
        quantity: variant?.inventory_quantity,
    }));

    if (loading) {
        return (
            <div>
                <Flex style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "250px"}}>
                    <Spin size="large" />
                </Flex>
            </div>
        );
    }
    if(error) {
        return (
            <Result
                status={error}
                title={error}
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button type="primary" onClick={handleClose}>Back Home</Button>}
            />
        )
    }
    return (
        <>
            <div>
                <div className="position">
                    <div className="carousel-ui">
                        <Carousel
                            arrows={true}
                            prevArrow={<LeftCircleOutlined />}
                            nextArrow={<RightCircleOutlined />}
                        >
                            {dataSource?.images.map((image) => (
                                <div key={image.id}>
                                    <img
                                        src={image.src}
                                        alt={`image with ${image?.id}`}
                                        className="contentStyle"
                                    ></img>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <div className="refills">
                        <div>
                            <h1 className="heading1">{dataSource.title}</h1>
                            <div className="key-value-pair">
                                <span className="key">Product ID</span>
                                <span className="dot">:</span>
                                <span className="value">{dataSource?.id}</span>
                            </div>
                            <div className="key-value-pair">
                                <span className="key">Product Status</span>
                                <span className="dot">:</span>
                                <span className="value">{dataSource?.status}</span>
                            </div>
                            <div className="key-value-pair">
                                <span className="key">Vendor</span>
                                <span className="dot">:</span>
                                <span className="value">{dataSource?.vendor}</span>
                            </div>
                            <div className="key-value-pair">
                                <span className="key">Product Title</span>
                                <span className="dot">:</span>
                                <span className="value">{dataSource?.title}</span>
                            </div>
                        </div>
                        <Divider />
                        <div className="product_variant_outer">
                            <h1 className="heading1">Variants</h1>
                            <Table
                                dataSource={tableData}
                                columns={columns}
                                pagination={false}
                                scroll={{ x: true }}
                            />
                        </div>
                        <Divider />
                        <div>
                            <h1 className="heading1">Description</h1>
                            <div className="description-inner-container">
                                {typeof dataSource?.body_html === "string"
                                    ? HTMLReactParser(dataSource.body_html.toString())
                                    : null}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};
export default ProductModal;